const api_url = "https://api.rosecoco.fun/api"

// define a mixin object
var datumMixin = {
  created: function () {
    // this.hello()
  },
  methods: {
    alertNotification(verticalAlign, horizontalAlign, color, message) {
      this.$notify({
        message: message,
        timeout: 3000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color
      });
    },
    calculateAge(dob) {

      var dateOfBirth = new Date(dob);
      var currentDate = new Date();
      // Calculate the age
      var age = currentDate.getFullYear() - dateOfBirth.getFullYear();

      // Adjust the birthdate based on the current month and day
      if (
        currentDate.getMonth() < dateOfBirth.getMonth() ||
        (currentDate.getMonth() === dateOfBirth.getMonth() &&
          currentDate.getDate() < dateOfBirth.getDate())
      ) {
        age--;
      }

      return age;
    },
    getDisplayDate(date) {
      const inputDate = new Date(date);
      const now = new Date();
    
      const diffInSeconds = Math.floor((now - inputDate) / 1000);
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      const diffInHours = Math.floor(diffInMinutes / 60);
      const diffInDays = Math.floor(diffInHours / 24);
    
      if (diffInSeconds < 60) {
        return `${diffInSeconds} second${diffInSeconds !== 1 ? 's' : ''} ago`;
      } else if (diffInMinutes < 60) {
        return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
      } else if (diffInHours < 24) {
        return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
      } else if (diffInDays === 1) {
        return `Yesterday at ${this.tConvert(inputDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }))}`;
      } else if (diffInDays < 7) {
        return `${inputDate.toLocaleDateString('en-US', { weekday: 'long' })} at ${this.tConvert(inputDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }))}`;
      } else {
        return inputDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        });
      }
    },
    
    tConvert(time) {
      const [hour, minute, second] = time.split(':');
      const period = +hour >= 12 ? 'PM' : 'AM';
      const adjustedHour = +hour % 12 || 12;
      return `${adjustedHour}:${minute} ${period}`;
    },
    
    formatModelDetails(model)
    {
        let location_details = (model.location_details!='[]') ? JSON.parse(model.location_details) : null
        let personal_details = (model.personal_details!='[]') ? JSON.parse(model.personal_details) : null
        let payment_details = (model.payment_details!='[]') ? JSON.parse(model.payment_details) : null


        //2 . Prep it
        model.location_details = location_details
        model.personal_details = personal_details
        model.payment_details = payment_details

        if (//(model.payment_details)
        (model.personal_details)
        &&(model.location_details))
        {
            model.hasAllDetails = true
        }
        else
        {
            model.hasAllDetails = false

        }

        return model


    },
    tConvert(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    removeLocalStorageItems() {
      localStorage.clear();
    }
    
  }
}

export { api_url, datumMixin }
